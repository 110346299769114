// From an API standpoint the recommendation type that's sent
export const RECOMMENDATION_API_TYPE = {
  Cart: 0,
  FiftyGreen: 1,
  HomePage: 2,
  MiniPDP: 3,
  Product: 4,
  Search: 5,
  ShoppingList: 6,
  ShoppingListProduct: 7,
}

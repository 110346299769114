import styles from './Divider.module.scss'
import PropTypes from 'prop-types'
import Box from '../Box'
import classNames from 'classnames'
/**
 * Divider component,
 * Doesn't work a as box component for now renders empty child error.
 * @param {*} margin
 */
function Divider({ className, ...props }) {
  const merged = {
    ...props,
    className: classNames(styles.defaultStyles, className),
  }
  return <Box as="hr" {...merged} />
}
Divider.propTypes = {
  className: PropTypes.string,
}

export default Divider

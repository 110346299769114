import ContentLoader from 'react-content-loader'

const ProductSectionSkelton = () => {
  return (
    <ContentLoader
      height={'390'}
      style={{ width: '100%' }}
      uniqueKey="product-section-skelton"
    >
      <rect x="0" y="20" rx="5" ry="5" width="100%" height="140" />
      <rect x="0" y="180" rx="0" ry="0" width="100%" height="30" />
      <rect x="0" y="220" rx="0" ry="0" width="100%" height="8" />
      <rect x="0" y="240" rx="0" ry="0" width="100%" height="8" />

      <circle cx="12" cy="280" r="10" />
      <circle cx="34" cy="280" r="10" />
      <circle cx="58" cy="280" r="10" />
      <circle cx="82" cy="280" r="10" />
      <rect x="0" y="310" rx="0" ry="0" width="100%" height="1" />
      <rect x="0" y="330" rx="0" ry="0" width="100" height="24" />
    </ContentLoader>
  )
}

export default ProductSectionSkelton

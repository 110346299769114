export default {
  en: {
    previous: 'Previous button to reach next banner',
    next: 'Next button to reach next banner',
    default: 'Click arrow to reach banner',
  },
  fr: {
    previous: 'Bouton précédent pour accéder à la bannière précédente',
    next: 'Bouton suivant pour atteindre la bannière suivante',
    default: 'Cliquez sur la flèche pour atteindre la bannière',
  },
}

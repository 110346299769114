import CarouselArrow from 'shared/Carousel/CarouselArrow'
export const carouselSettings = {
  dots: false,
  infinite: false,
  autoplay: false,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 6,
  initialSlide: 0,
  centerMode: false,
  rtl: false,
  nextArrow: <CarouselArrow variant="small" />,
  prevArrow: <CarouselArrow variant="small" />,
  responsive: [
    {
      breakpoint: 1281,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 820,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

import Slider from 'react-slick'
import PropTypes from 'prop-types'

//import './Carousel.module.scss'  TODO: need to make it modular

/**
 * Name: Carousel that uses react-click
 * For documentation go to http://react-slick.neostack.com
 * Desc: Renders a carousel with images
 * @param {*} props
 * @returns Carousel
 */
const Carousel = (props) => {
  return <Slider {...props}>{props.children}</Slider>
}

// Default Props
Carousel.defaultProps = {
  slidesToShow: 1,
  autoplaySpeed: 5000,
  dots: true,
  autoplay: true,
  infinite: true,
  speed: 100,
}

// PropType validation
Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  dot: PropTypes.bool,
  autoPlay: PropTypes.bool,
  infinite: PropTypes.bool,
  speed: PropTypes.number, // In milliseconds
  slidesToShow: PropTypes.number,
  nextArrow: PropTypes.node,
  prevArrow: PropTypes.node,
  breakpoints: PropTypes.array,
  autoplaySpeed: PropTypes.number, // In milliseconds
}
export default Carousel

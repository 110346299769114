import PropTypes from 'prop-types'
import { formatSuspensionMessage } from './SuspendedMessageUtils'
import { ENGLISH } from '~/redux/constants'
/**
 * Small function component that returns a string and gets
 * the suspension message from account details
 * @param {Object} details the account details object
 * @param {string} language language
 */
function SuspendedMessage({ details, language }) {
  const { suspendedDateFrom, suspendedDateUntil } = details
  const message = formatSuspensionMessage(
    suspendedDateFrom,
    suspendedDateUntil,
    language
  )
  return message
}

export default SuspendedMessage

// Props Validation
SuspendedMessage.propTypes = {
  details: PropTypes.object,
  language: PropTypes.string,
}

// Default Props
SuspendedMessage.defaultProps = {
  details: {},
  language: ENGLISH,
}

import PropTypes from 'prop-types'
import { generateArrayByLength, toCamelCase } from 'services/utils/utils'
import Carousel from 'shared/Carousel'
import HeadingSkelton from 'shared/Heading/HeadingSkeleton'
import ProductCard from 'shared/ProductCard'
import { GRID_VIEW } from 'shared/ProductCard/ProductCardConstants'
import ProductSectionSkelton from 'shared/ProductSection/ProductSectionSkeleton'
import SectionTitle from 'shared/SectionTitle'
import Container from '~/components/shared/Container'
import { ENGLISH } from '~/redux/constants'
import { carouselSettings } from './carouselSettings'
import styles from './ProductSection.module.scss'

/**
 * Name: ProductSection
 * Desc: render product section list
 * @param {string} language
 * @param {array} productList
 * @param {boolean} showYourPriceFlag
 * @param {boolean} showContractCareOf
 * @param {boolean} showContractIcon
 * @param {string} title
 * @param {boolean} showCheckbox
 * @param {func} addToCartHandler
 * @param {boolean} isLoggedIn
 * @param {object} callingLocation
 * @param {string} pageName
 * @param {bool} showSkeleton
 * @param {bool} showProductReviews
 */

const ProductSection = ({
  language = ENGLISH,
  title,
  isLoggedIn,
  productList = [],
  showYourPriceFlag,
  showContractCareOf,
  showContractIcon,
  addToCartHandler,
  showCheckbox,
  callingLocation,
  pageName,
  showSkeleton,
  showProductReviews,
  dataTest,
  enableInstrumentation = false,
  onCheckBoxChange = () => {},
  selectedProducts = [],
}) => {
  return (
    <section
      className={styles.productWrapper}
      data-test={dataTest || toCamelCase(title)}
    >
      <Container>
        {!showSkeleton ? <SectionTitle title={title} /> : <HeadingSkelton />}
        <Carousel {...carouselSettings}>
          {!showSkeleton
            ? productList?.map((item = {}, index) => {
                return (
                  <div
                    className={styles.productContent}
                    key={`content-${index}`}
                  >
                    <ProductCard
                      product={item}
                      showContractIcon={showContractIcon}
                      isLoggedIn={isLoggedIn}
                      addToCartEnable={true}
                      zoomIcon={true}
                      savingPriceEnable={true}
                      view={GRID_VIEW}
                      key={item.productCode}
                      language={language}
                      showCheckbox={showCheckbox}
                      showYourPriceFlag={showYourPriceFlag}
                      showContractCareOf={showContractCareOf}
                      checkboxChangeHandler={onCheckBoxChange}
                      addToCartHandler={addToCartHandler}
                      className="overFlowVisible"
                      callingLocation={callingLocation}
                      pageName={pageName}
                      showProductReviews={showProductReviews}
                      enableInstrumentation={enableInstrumentation}
                      richRelevanceUrl={item.RichRelevanceAnalyticsUrl}
                      selectedProducts={selectedProducts}
                      productCardIndex={index}
                    />
                  </div>
                )
              })
            : generateArrayByLength(6).map((item) => (
                <div className={styles.productContent} key={item}>
                  <ProductSectionSkelton />
                </div>
              ))}
        </Carousel>
      </Container>
    </section>
  )
}

ProductSection.defaultProps = {
  text: '',
  showCheckbox: false,
  productList: [],
  dataTest: '',
}

// Props Validation
ProductSection.propTypes = {
  productList: PropTypes.array,
  language: PropTypes.string,
  title: PropTypes.string,
  showYourPriceFlag: PropTypes.bool,
  showContractCareOf: PropTypes.bool,
  showContractIcon: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  addToCartHandler: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  callingLocation: PropTypes.object,
  pageName: PropTypes.string,
  showSkeleton: PropTypes.bool,
  showProductReviews: PropTypes.bool,
  dataTest: PropTypes.string,
  enableInstrumentation: PropTypes.bool,
  onCheckBoxChange: PropTypes.func,
  selectedProducts: PropTypes.array,
}

export default ProductSection

import classNames from 'classnames'
import omit from 'lodash/omit'
import PropTypes from 'prop-types'
import { ENGLISH } from '~/redux/constants'
import styles from './CarouselArrow.module.scss'
import messages from './messages'

/**
 * Name: CarouselArrow
 * Desc: render carousel arrow
 * @param {*} props
 * @param {string} className
 * @param {string} carouselAddedClass
 * @param {string} type
 */
const CarouselArrow = (props) => {
  const {
    className,
    variant,
    carouselAddedClass,
    as = 'div',
    language = ENGLISH,
  } = props
  const classes = classNames(className, carouselAddedClass, {
    [styles.smallAndTransparent]: variant === 'small',
  })

  const Component = as
  const arrowType = getArrowType(props.className)
  const message = messages[language][arrowType]
  const omittedProps = omit(props, ...propsToOmit)
  return (
    <Component {...omittedProps} className={classes}>
      {props.children || message}
    </Component>
  )
}

CarouselArrow.propTypes = {
  as: PropTypes.string,
  language: PropTypes.string,
  carouselAddedClass: PropTypes.any,
  children: PropTypes.node,
  className: PropTypes.any,
  type: PropTypes.any,
  variant: PropTypes.string,
}

const propsToOmit = [
  'slideCount',
  'currentSlide',
  'carouselAddedClass',
  'variant',
  'language',
  'as',
]

export default CarouselArrow

function getArrowType(className = '') {
  const isNextArrow = className.includes('next')
  const isPreviousArrow = className.includes('prev')
  if (!isNextArrow && !isPreviousArrow) {
    return 'default'
  }
  const type = isNextArrow ? 'next' : 'previous'
  return type
}

import { ENGLISH } from '@redux/constants'
import { getOldApiUrl, getApiUrl } from 'services/utils/configUtils'
import { checkArgsEmpty, sendRequest } from './apiHelper'

const OLD_API_URL = getOldApiUrl()
const API_URL = getApiUrl()

/**
 * Name: buildFetchPromoBanner
 * @param {number} type
 * @param {string} language
 */
const buildFetchPromoBannerData = async (args) => {
  const [language = ENGLISH, type] = args
  const url = `${OLD_API_URL}/api/promoBanner/${type}`
  const options = {
    headers: {
      'Accept-Language': `${language}-CA`,
    },
  }
  const response = await sendRequest(url, options)
  return response
}

const buildFetchPromoBanners = async (url) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const response = await sendRequest(url, options)
  return response
}

const buildFetchAccountNoList = async (clientId) => {
  const url = `${API_URL}/clients/clientNumbers/${clientId}`
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const response = await sendRequest(url, options)
  return response
}

export const getPromotionBannerData = checkArgsEmpty(buildFetchPromoBannerData)
export const getPromotionBanners = checkArgsEmpty(buildFetchPromoBanners)
export const getAccountNoList = checkArgsEmpty(buildFetchAccountNoList)

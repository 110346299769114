import { ENGLISH } from '~/redux/constants'
import { getCookie } from '~/services/cookieManager'
import { RECOMMENDATION_API_TYPE } from './recommendationConstants'
import { get } from './recommendationOperations'

/**
 * Name: fetchRecentAndRecommendationProducts
 * @param {*} language
 * @param {*} type // expected values Cart = 0, FiftyGreen = 1, HomePage = 2, MiniPDP = 3, Product = 4, Search = 5, ShoppingList = 6, ShoppingListProduct = 7
 */

export async function fetchRecentAndRecommendationProducts({
  language = ENGLISH,
  type = RECOMMENDATION_API_TYPE.HomePage,
  usePwgsRecommendationType = 0,
  productCodes = [],
  categoryName = '',
  keyword = '',
} = {}) {
  const reqParams = {
    TrackingId: getCookie('RES_TRACKINGID') || '', //Recommendations Tracking ID (we'll have to discuss that)
    Keyword: keyword, //Keyword used in the search page
    CategoryName: categoryName, //Category name clicked in the category menu
    CategoryId: '', //Not sure
    PageNumber: 0, //Page number of the current search page
    ProductCode: '', //Product code of the current PDP
    ProductCodeList: productCodes, //Product codes to get recommendations from (cart products when in cart page, results when in search, etc)
    GreenCharge: 0.0, //not sure
    PreSearchFilter: usePwgsRecommendationType ? 1 : 0, //For PWGS users (All = 0, StandingOffers = 1, None = 2)
    MaxRecommendationCount: 25,
  }
  const data = (await get(language, type, reqParams)) || {}
  const {
    RecommendationProducts: recommendations = [],
    RecentlyViewedItemsProducts: recentViewed = [],
  } = data
  const { Products: recommendProducts = [] } = recommendations.length
    ? recommendations?.[0]
    : []
  const finalData = {
    recentlyViewedProducts: removeEmptyValuesAndStrings(recentViewed),
    recommendationProducts: removeEmptyValuesAndStrings(recommendProducts),
  }
  return finalData
}

function removeEmptyValuesAndStrings(values = []) {
  return values.filter((value) => Object.keys(value).length !== 0)
}

import { getOldApiUrl } from 'services/utils/configUtils'
import { ENGLISH } from '~/redux/constants'
import { checkArgsEmpty, sendRequest } from '../apiHelper'
import { formatProductDetail } from '~/api/productOperations'

const OLD_API_URL = getOldApiUrl()

/**
 * Name: fetchRecentAndRecommendationProducts
 * Desc: get product list for recent product
 * @param {*} language
 * @param {*} type // expected values Cart = 0, FiftyGreen = 1, HomePage = 2, MiniPDP = 3, Product = 4, Search = 5, ShoppingList = 6, ShoppingListProduct = 7
 */
const buildFetchRecentAndRecommendationProducts = async (args) => {
  const [language = ENGLISH, type, reqParams] = args

  const url = `${OLD_API_URL}/api/recommendationsRail/${type}`
  const options = {
    method: 'POST',
    body: JSON.stringify(reqParams),
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': `${language}-CA`,
    },
  }
  const {
    RecentlyViewedItemsProducts = [],
    RecommendationProducts = [],
    ...rest
  } = await sendRequest(url, options)
  const productList = formatProductDetail(RecentlyViewedItemsProducts, language)
  const [{ Products = [], ...restRecommendationInfo } = {}] =
    RecommendationProducts
  const recommendationProductList = formatProductDetail(Products, language)

  return {
    RecentlyViewedItemsProducts: productList,
    RecommendationProducts: [
      { Products: recommendationProductList, ...restRecommendationInfo },
    ],
    ...rest,
  }
}

export const get = checkArgsEmpty(buildFetchRecentAndRecommendationProducts)

import { ENGLISH } from '~/redux/constants'
import messages from './messages'
import SuspendedMessage from './SuspendedMessage'
import { isSuspended } from '~/components/shared/Utils/userUtils'
import { showNotification } from '../../AlertNotificationUtils'

/**
 * Function that shows the suspension message
 * @param {*} details the account details object
 */
export const showSuspensionMessage = (details, language) => {
  // Get the message
  const suspensionMessage = (
    <SuspendedMessage details={details} language={language} />
  )
  // Show it
  showNotification(suspensionMessage, {
    id: 'suspension-message-placeholder',
  })
}

/**
 * Handler for suspension messages, if the account is suspended show the message
 * @param {Object} details The account details
 * @param {string} language and the language to show it in the appropriate language
 */
export const handleSuspensionMessage = (details = {}, language) => {
  isSuspended(details) && showSuspensionMessage(details, language)
}

/**
 * Function that formats the actual suspension message
 * @param {string} fromDate the from date as a string i.e "2019-11-04T00:00:00"
 * @param {string} toDate the to date as a string i.e "2019-11-04T00:00:00"
 */
export const formatSuspensionMessage = (
  fromDate,
  toDate,
  language = ENGLISH
) => {
  const suspensionMessage =
    !fromDate || !toDate
      ? messages[language].halfMessage
      : messages[language].fullMessage
  const [from, to] = formatDatesForSuspensionMessage(fromDate, toDate)
  return suspensionMessage.replace('{from}', from).replace('{to}', to)
}

/**
 * Formats dates
 * @param  {Array} args expect an array of dates, and formats them as YYYY-MM-DD
 */
const formatDatesForSuspensionMessage = (...args) =>
  args.map((date) => (date ? new Date(date).toISOString().slice(0, 10) : date))

import ContentLoader from 'react-content-loader'

const HeadingSkelton = () => {
  return (
    <ContentLoader
      height={'45'}
      style={{ width: '100%' }}
      uniqueKey="heading-skelton"
    >
      <rect x="0" y="0" rx="0" ry="0" width="100%" height="45" />
    </ContentLoader>
  )
}

export default HeadingSkelton

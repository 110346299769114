import { getHostUrl, getPeacockApiUrl } from 'services/utils/configUtils'
import {
  getPromotionBannerData,
  getPromotionBanners,
  getAccountNoList,
} from '~/api/promotionBannerOperations'
import { PROMOTION } from '~/components/shared/ProductCard/ProductCardImage/ProductCardImageConstants'
import { getImageUrl } from '~/components/shared/ProductCard/ProductCardImage/ProductCardImageUtils'
import { ENGLISH, FRENCH } from '~/redux/constants'
import { ORDER } from './promotionBannerServiceConstants'
import { camelCaseKeys } from './utils'
import { getUrl } from 'shared/EwayRouter'
import { BROWSE_PRODUCTS_PROMOTION_ROUTE } from 'shared/EwayRouter/EwayRouterConstants'

const HOST_URL = getHostUrl()
const PEACOCK_API_URL = getPeacockApiUrl()

/**
 * Name: fetchPromoBanner
 * @param {string} language
 * @param {number} type
 */

export async function fetchPromotionBanners(language = ENGLISH, type) {
  const data = camelCaseKeys(await getPromotionBannerData([language, type]))
  const { promotions: promos = [], selectedPromotion = {} } = data
  const promotions = promos.map((item) => {
    const { imageName, promoType: type } = item
    // If promoType is ORDER_PROMO_TYPE
    // than redirect the user to the blank keyword search
    // else promotion banner
    const suffix =
      type === ORDER ? `Keyword?keyword=` : `Promotion/${item.sOFPromoID}`
    return {
      ...item,
      ...addImageConditionally(imageName),
      link: `${HOST_URL}/${language}/Shopping/Search/SearchBy${suffix}`,
    }
  })
  return {
    ...data,
    selectedPromotion: {
      ...selectedPromotion,
      ...addImageConditionally(selectedPromotion.imageName),
    },
    promotions,
  }
}

function addImageConditionally(imageName) {
  return {
    ...(imageName && {
      imageName: getImageUrl({ imageName, type: PROMOTION }),
    }),
  }
}

export async function fetchNewPromotionBanners(
  language = ENGLISH,
  newPromotionQueryParams = {},
  clientId,
  promoId = ''
) {
  try {
    // get AccountNoList
    const accountList = await getAccountNoList(clientId)

    // get new promotions banner from promoTool
    const queryParams = {
      ...newPromotionQueryParams,
      accountNoList: [...accountList],
    }

    const {
      logonName,
      customerType,
      shipTo,
      warehouseNo,
      segment,
      sicCode,
      divisionCode,
      accountNoList = [],
      appliedPromoList = [],
    } = queryParams
    const iteratorPromoList = appliedPromoList?.values()
    const iteratorAccountNoList = accountNoList?.values()
    let formattedAppliedPromoList = ''
    let formattedAccountNoList = ''
    for (const element of iteratorPromoList) {
      formattedAppliedPromoList += `&appliedPromoList=${element}`
    }
    for (const element of iteratorAccountNoList) {
      formattedAccountNoList += `&accountNoList=${element}`
    }
    const url = `${PEACOCK_API_URL}/promotions/banners?logonName=${logonName}&sicCode=${sicCode}&customerType=${customerType}&shipTo=${shipTo}&warehouseNo=${warehouseNo}&segment=${segment}&divisionCode=${divisionCode}${formattedAccountNoList}${formattedAppliedPromoList}`

    const promotionBanners = await getPromotionBanners(url)
    const formattedResult = formattedNewPromoData({
      language,
      promotionBanners,
      promoId,
    })
    return formattedResult
  } catch (error) {
    return {}
  }
}

export async function fetchSinglePromotionBanner({
  newPromotionQueryParams = {},
  clientId,
  promoId = '',
}) {
  try {
    // get AccountNoList
    const accountList = await getAccountNoList(clientId)

    // get new promotions banner from promoTool
    const queryParams = {
      ...newPromotionQueryParams,
      accountNoList: [...accountList],
    }

    const {
      logonName,
      customerType,
      shipTo,
      warehouseNo,
      segment,
      sicCode,
      divisionCode,
      accountNoList = [],
      appliedPromoList = [],
    } = queryParams
    const iteratorPromoList = appliedPromoList?.values()
    const iteratorAccountNoList = accountNoList?.values()
    let formattedAppliedPromoList = ''
    let formattedAccountNoList = ''
    for (const element of iteratorPromoList) {
      formattedAppliedPromoList += `&appliedPromoList=${element}`
    }
    for (const element of iteratorAccountNoList) {
      formattedAccountNoList += `&accountNoList=${element}`
    }
    const url = `${PEACOCK_API_URL}/promotions/${promoId}/view-model?id=${promoId}&logonName=${logonName}&sicCode=${sicCode}&customerType=${customerType}&shipTo=${shipTo}&warehouseNo=${warehouseNo}&segment=${segment}&divisionCode=${divisionCode}${formattedAccountNoList}${formattedAppliedPromoList}`
    const promotionDetails = await getPromotionBanners(url)

    const {
      images = {},
      generalDetails: { id = '', name = {}, description = {} } = {},
      qualifierDetails: { qualifierType } = {},
    } = promotionDetails || {}

    const promotionBanners = formattedNewPromoData({
      promotionBanners: [{ id, name, description, images, qualifierType }],
      promoId,
    })

    return promotionBanners
  } catch (error) {
    return {}
  }
}

function formattedNewPromoData({ promotionBanners, promoId }) {
  const en = promotionBanners.map((item) => {
    const { id, name, description, images, qualifierType, ...rest } = item

    const imageName = promoId ? images.largeImageEn : images.mediumImageEn
    const promotionUrlEn = getUrl(BROWSE_PRODUCTS_PROMOTION_ROUTE, {
      specificLanguage: ENGLISH,
    })
    return {
      ...rest,
      ...addImageConditionally(imageName),
      promoName: name[ENGLISH],
      text: description[ENGLISH],
      SOFPromoID: id,
      PromoType: qualifierType,
      link: `${promotionUrlEn}${item.id}`,
    }
  })

  const fr = promotionBanners.map((item) => {
    const { id, name, description, images, qualifierType, ...rest } = item
    const imageName = promoId ? images.largeImageFr : images.mediumImageFr
    const promotionUrlFr = getUrl(BROWSE_PRODUCTS_PROMOTION_ROUTE, {
      specificLanguage: FRENCH,
    })
    return {
      ...rest,
      ...addImageConditionally(imageName),
      promoName: name[FRENCH],
      text: description[FRENCH],
      SOFPromoID: id,
      PromoType: qualifierType,
      link: `${promotionUrlFr}${item.id}`,
    }
  })

  const selectedPromotionEn = en?.[0] || {}
  const selectedPromotionFr = fr?.[0] || {}

  return {
    [ENGLISH]: { promotions: en, selectedPromotion: selectedPromotionEn },
    [FRENCH]: { promotions: fr, selectedPromotion: selectedPromotionFr },
  }
}

export default {
  en: {
    fullMessage:
      'At the request of your Organization, we will be holding all orders during the period of {from} until {to} due to site closures.',
    halfMessage:
      'At the request of your Organization, we will be holding all orders due to site closures.',
  },

  fr: {
    fullMessage:
      'À la demande de votre entreprise, nous conserverons toutes vos commandes pour la période du {from} au {to} en raison des fermetures de sites.',
    halfMessage:
      'À la demande de votre entreprise, nous conserverons toutes vos commandes pour une période de temps en raison des fermetures de sites.',
  },
}
